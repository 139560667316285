.page-main .cards{
    margin-top: 20px;
}
.cards {
    margin-top: 20px;
    &__container {
        /* max-width: 850px; */
    }

    &__title {
        &.title{
            justify-content: flex-start;
            font-size: toRem(28);
            line-height: 1.2;
            font-weight: 500;
        }
    }

    &__body {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 60px;
    }
    &__wrapper{
        width: 100%;
    }
    &__item {
    }
}
.item-card {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
   
    &__image {
        display: block;
        min-width: 200px;
        width: 200px;
        height: 200px;     
        transition: all .2s ease-in-out .1s;
        img{
            border-radius: 50%;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all .2s ease-in-out .1s;

        }
    }

    &__body {
       
        

    }
}
.info-model {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 10px;
    width: 100%;

    &__name {
    }

    &__values {
    }

    &__text {
    }
    &__social {
    }
}
.name {
    display: flex;
    gap: 20px;
    align-items: center;
    &__body{
        display: flex;
        gap: 15px;
        align-items: center;
    }
    &__nickname {
        font-size: toRem(24);
        font-weight: 500;
        line-height: 120%;
        color: var(--card-item-info-svg);
    }
    &__row{
        display: flex;
        align-items: center;
        gap: 15px;
    }

    &__link {
        font-size: toRem(20);
        line-height: 120%;
        position: relative;
        padding-left: 10px;
        color: var(--card-item-info-svg);
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 2px;
            height: 100%;
            background-color: var(--card-item-info-svg);
        }
    }
    &__add{
        width: 22px;
        height: 20px;
    }
}
.item-values {
    display: flex;
    gap: 20px;

    &__item {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    &__image {
        width: 25px;
        height: 25px;
        path{
            fill: var(--card-item-info-svg);
        }
    }

    &__ammount {
        font-size: toRem(16);
        font-weight: 500;
        line-height: 120%;
        color: var(--card-item-info-svg);
    }
    &__free-trial{
        border-radius: 15px;
        background-color: #00aff0;
        font-size: toRem(12);
        font-weight: 700 !important;
        color: #fff !important;
        line-height: 1;
        padding: 3px 5px;
        white-space: nowrap;
    }
}
.likes {
}
.photos {
}
.videos {
}
.price {
    p{
        text-transform: uppercase;
        font-weight: 600;
        color: var(--card-item-info-svg);
    }
}
.description {

    &__text {
        text-align: left;
        font-size: toRem(18);
        line-height: 110%;       
        color: var(--card-item-info-svg); 
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        
    }
}
.social {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    &__link {
        transition: transform .2s ease-in-out;
        svg{
            width: 20px;
            height: 20px;
        }
    }
}
.link-of{
    font-size: toRem(18);
    color: #e8a010;
}


@media (hover:hover){
    .item-card {
        &__image {
            &:hover{
               

                
                img{
                    transform: scale(1.5);
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 15px;
                    transition: all .2s ease-in-out .1s;
                    
        
                }
            }
            
        }
        
    }
    .social {
        &__link {
            &:hover{
                transform: translate(-1px,-2px);
                transition: transform .2s ease-in-out;
            }
        }
    }
}
@media (max-width:900px){
    .name{
        &__body{
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
        }
    }
}
@media (max-width:$mobile){
    .cards{
        margin-top: 0px;
    }
    .item-card {
        
    
        &__body {
    
        }
    }
    .name {
        gap: 10px;
        &__body{
            gap: 5px;
        }
        &__nickname {
            font-size: toRem(20);
        }
    
        &__link {
            font-size: toRem(18);
            padding-left: 5px;
            
        }
    }
    .item-values {
    
        &__item {
        }
    
        &__image {
            width: 20px;
            height: 20px;
        }
    
        &__ammount {
            font-size: toRem(14);
        }
    }
    .description {

        &__text {
            font-size: toRem(16);
            
        }
    }
}
@media (max-width:576px){    
    .cards{
        
        &__container{
            height: 620px;
            overflow: hidden;
            display: flex;
            align-items: center;
        }
        &__body{
            align-items: flex-start;
            justify-content: flex-start;
            scroll-snap-type: y mandatory;
            scroll-snap-points-y: repeat(580px);            
			width: 100%;
			height: 620px;
			display: flex;
			flex-flow: column nowrap;
  			overflow: auto;
            gap: 80px;
           
        }
        &__wrapper{
            padding: 15px 0px;
        }
        &__item{
            margin: 0px 0px 5px 2px;
            width: calc(100% - 7px);			
			scroll-snap-align: center;
			flex:  none;
        }
    }
    .item-card {
        box-shadow: 0 1px 6px rgba(32,33,36,.28);
        border-radius: 15px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;        
        
        overflow: hidden;    
        &__image {
            display: block;
            min-width: 100%;
            width: 100%;
            height: 325px;
            border-radius: 0px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 0px;
    
            }
        }
    
        &__body {
            padding: 10px 5px 20px 5px;
    
        }
    }
    
    .name {
        &__body{
            flex-direction: column;
            gap: 5px;
            width: 100%;
            align-items: flex-start;
            
        }
        &__nickname {
            font-size: toRem(24);
        }
        &__row{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-around;
        }
        &__link {
            display: block;
            font-size: toRem(20);
            line-height: 120%;
            position: relative;
            padding-left: 0;
            flex: 1 1 auto;
            
            
            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 0px;
                height: 100%;
                background-color: #000;
            }
        }
        &__add{
            justify-self: flex-end;
        }
    }
    .item-values {
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        flex-wrap: wrap;
    
        &__item {
            display: flex;
            align-items: center;
            gap: 5px;
        }
    
        &__image {
            width: 20px;
            height: 20px;
        }
    }
    .description {
        
        &__text {
            font-size: toRem(16);
            -webkit-line-clamp: 4;
            
        }
    }
}
@media(max-width:410px){
    .description{
        &__text{
            width: 310px;
        }
    }
}
@media(max-width:370px){
    .description{
        &__text{
            width: 270px;
        }
    }
}
