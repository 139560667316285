.navigation {
    margin-top: 20px;
    &__container {
        max-width: 850px;
    }

    &__body {
        display: flex;
        gap: 5px;
        align-items: center;
    }

    &__link {
        font-size: toRem(16);
        line-height: 1.5;
        text-transform: capitalize;
        text-decoration: underline;
    }

    &__arrow {
        transform: rotate(-90deg) translate(0,0);
    }
}
@media (max-width:$tablet){
    .navigation{
        margin-top: 10px;
    }
}