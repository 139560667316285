.search-time {

    &__container {
        max-width: 850px;
    }

    &__text {
        font-size: toRem(16);
        line-height: 1.5;
        color: rgb(108, 117, 125);
    }
}
