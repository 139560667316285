.search-module {
    margin-top: 20px;

    &__container {}

    &__form {
        margin: 0 auto;
    }

    &__links {
        margin: 20px auto 0;
    }
}

.form {
    position: relative;
    width: 500px;

    &__input-search {
        display: block;
        width: 100%;
        font-size: toRem(16);
        line-height: 120%;
        height: 40px;
        padding: 10px;
        border-radius: 10px;
        background-color: var(--page-bg);
        border: 1px solid #dfe1e5;
        transition: box-shadow .2s ease;

        &:focus,
        &:focus-visible {
            box-shadow: 0 1px 6px rgba(32, 33, 36, .28);
            transition: box-shadow .2s ease;


        }
    }

    &__cancel {
        position: absolute;
        right: 45px;
        top: 50%;
        background-color: #fff;
        width: 20px;
        height: 20px;
        transform: translate(0, -50%);

        svg {
            width: 20px;
            height: 20px;

            path {}
        }
    }

    &__button {
        position: absolute;
        right: 5px;
        top: 50%;
        background-color: #fff;
        width: 30px;
        height: 30px;
        transform: translate(0, -50%);

        svg {
            width: 100%;
            height: 100%;

            path {}
        }

    }
}

.links {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    &__item {}
}

.item-link {
    border: 1px solid lightgray;
    border-radius: 20px;

    &__text {
        text-transform: capitalize;
        font-size: toRem(16);
        line-height: 1.5;
        padding: 2px 13px;
        color: #6c757d;
    }

    &__custom-select {}

    /* &__select {
        position: relative;
        &.is-active .item-link{
            &__select-header{
               
            }
            &__select-body{
                display: block;
                
            }
            &__select-icon{
                transform: translate(0,-50%) rotate(180deg);
                transition: all 0.3s ease;
    
            }
        }
    }

    &__select-header {
        cursor: pointer;
        display: flex;
        font-size: toRem(16);
        line-height: 1.5;
        padding: 2px 25px 2px 13px;
        color: #6c757d;
        text-transform: capitalize;
    }

    &__select-current {
        text-transform: capitalize;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 55px;
        overflow: hidden;
    }

    &__select-icon {
        position: absolute;
        top: 50%;
        right: 0px;
	    height: 20px;
        width: 20px;
	    right: 5px;
	    text-align: center;	
        transition: all 0.3s ease;
        transform: translate(0,-50%);
        svg{
            width: 100%;
            height: 100%;
            path{
                fill: #6c757d;
            }
        }
    }

    &__select-body {
        display: none;
        left: 0;
        position: absolute;
        right: 0;
        top: 102%;
        background-color: #fff;
        max-height: 300px;
        width: 280px;
        overflow: auto;
        border-radius: 15px;
        border: 1px solid #eee;        
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
        padding: 5px 0;
        inset: auto auto 0px 0px;
        transform: translate(13px, -30px);
        
    }

    &__select-item {
        display: block;
        cursor: pointer;
        color: #fd37b7;
        padding: 4px 16px;
        font-size: toRem(16);
        line-height: 1.5;
    } */

    &.chosen {
        background-color: #00b1f5;
        border: none;

        span {
            color: #ffffff;
        }

        & .custom-select__select-box {
            color: #ffffff;
            background-color: #00b1f5;
            border-radius: 20px;
        }

        & .select-box__arrow {
            svg {
                path {
                    fill: #ffffff;
                }
            }
        }
    }
}

.item-link.chosen .options__title {
    color: #6c757d !important;
}

.custom-select {
    display: flex;
    gap: 20px;

    &__body {
        position: relative;
        width: 100%;
    }

    &__select-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2px 13px;
        color: #6c757d;
        cursor: pointer;
    }

    &__options {

        display: none;
        position: absolute;
        top: 30px;
        left: 0px;
        width: 280px;
        background-color: var(--page-bg);
        padding: 5px 0px;
        border-top: none;
        max-height: 175px;
        overflow-y: auto;
        z-index: 1;
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
        border-radius: 15px;
        scrollbar-color: #6969696b transparent;
        scrollbar-width: thin;
    }
}

.select-box {

    &__tags-input {}

    &__selected-options {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0;
    }

    &__tag {
        //background-color: #e0e2e3;
        color: #6c757d;
        border-radius: 5px;
        margin-right: 3px;
        padding: 2px 2px;
        display: flex;
        align-items: center;
    }

    &__remove-tag {
        margin-left: 6px;
        cursor: pointer;
        width: 15px;
        height: 15px;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__arrow {
        margin: 0 3px 0 0;
        transition: transform .3s ease;

        svg {
            width: 20px;
            height: 20px;
        }
    }
}

.options {
    &__selected {}

    &__drop-down {
        margin-top: 5px;

    }

    &__header {
        display: flex;
        align-items: center;
        gap: 10px;

        padding: 0 10px;

    }

    &__icon {
        width: 15px;
        height: 15px;

        &.rotate {
            transform: rotate(45deg);
        }
    }

    &__title {
        font-weight: 400;
        font-size: toRem(16);

        color: #6c757d;
    }

    &__body {
        padding: 6px 16px;
        display: none;

        &.drop {
            display: block;
        }

    }

    &__option {
        font-weight: 300;
        color: #6c757d;
        cursor: pointer;

        &.active {

            background-color: #f1f3f4;
            border-bottom: 1px solid #eaeaea;
        }
    }
}

.selected-options {
    margin: 5px;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 5px;

    &__item {
        padding: 3px 16px;
        justify-content: space-between;
        display: flex;
        align-items: center;
        gap: 5px;
        box-shadow: 0 1px 6px rgba(32, 33, 36, .28);
        color: white;
        border-radius: 15px;
        background-color: #00b1f5;
    }

    &__tag {}

    &__remove {
        display: block;
        width: 20px;
        height: 20px;

        svg {

            /* width: 100%;
            height: 100%; */
            g {
                rect {
                    fill: #00b1f5;
                }

                path {
                    stroke: #ffffff;
                }

            }
        }
    }
}

.opened {
    & .custom-select {
        &__select-box {}

        &__options {
            display: block;

        }
    }

    & .select-box {
        &__arrow {
            transform: rotate(180deg);
            transition: transform .3s ease;
        }
    }
}

.placeholder {
    white-space: nowrap;
}

.chosen {}


@media(hover:hover) {

    .tag:hover,
    .options__option:hover {
        background-color: #f1f3f4;
    }

    .form {
        &__input-search {
            &:hover {
                box-shadow: 0px 1px 6px rgba(32, 33, 36, .28);
                transition: box-shadow .2s ease;
            }

        }
    }

    .item-link {
        &:hover {
            background-color: #f1f3f4;
        }

        &__select-item {
            &:hover {
                background-color: #e9ecef;
            }
        }
    }
}

@media(max-width:$tablet) {
    .custom-select {
        &__options {
            left: -200px;

        }
    }
}

@media(max-height:660px) {
    .page-main .custom-select {
        &__options {
            transform: translate(0, -215px);
        }
    }
}

@media(max-width:$mobile) {

    .item-link {
        &__select-body {
            inset: auto 0px 0px auto;
            transform: translate(-10px, -30px);
        }
    }
}

@media(max-width:560px) {

    .form {
        width: 100%;
    }
}