.footer {
    border-top: 1px solid lightgray;

    &__container {
    }

    &__body {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
    }

    &__item {
    }
}
.item-footer {

    &__link {
        font-size: toRem(14);
        text-transform: capitalize;
        line-height: 1.2;
    }
}
