
.page-categories, .page-location, .page-model{
    padding: 10px 0px 30px 0px !important;
    
}


.title-side-menu {
    font-size: toRem(20);
    font-weight: 500;
    line-height: 120%;
    color: var(--side-menu-title);

}
.title {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    font-size: toRem(36);
    font-weight: 600;
    line-height: 120%;
    color: #000;
    &__img {
        width: 48px;
        height: 48px;
        path{            
                fill: #000;
            
        }
    }

    &__text {
        font-size: toRem(48);
        font-weight: 700;
        color: #000;
    }
}
.logo{
    display: flex;
		align-items: center;
		gap: 10px;
		svg{
			width: 30px;
			height: 30px;
		}
		p{
			font-size: toRem(30);
			font-weight: 700;
			line-height: 120%;
		}
}


/* .custom-select {
    display: flex;
    gap: 20px;
    &__body{
        position: relative;
        width: 700px;
        height: 50px;
    }
    &__select-box{
        background-color: #fff;
        border: 2px solid var(--side-menu-select-border);
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 0 10px;
        cursor: pointer;
        border-radius: 10px
    }
    &__options{
        display: none;
    position: absolute;
    width: 100%;
    background-color: #fff;
    border: 2px solid var(--side-menu-select-border);
    border-top: none;
    max-height: 225px;
    overflow-y: auto;
    z-index: 1;
    box-shadow:0px 4px 8px rgba(0,0,0,0.1);
    border-radius: 0 0 10px 10px;
    }
    &__tag-error-msg{
        &.error {
            color: red;
            margin-top: 8px;
        }
    }
    &__btn-submit{
        position: absolute;
    top: 50%;
    right: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    transform: translate(0,-50%);
    svg{
        width: 30px;
        height: 30px;
    }

    }
}
.select-box{
    &__tags-input{

    }
    &__selected-options{
        display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    }
    &__tag{
        background-color: var(--side-menu-select-border);
        color: #000;
        border-radius: 5px;
        margin-right: 3px;
        padding: 5px 5px;
        display: flex;
        align-items: center;
    }
    &__remove-tag{
        margin-left: 6px;
        cursor: pointer;
        width: 15px;
        height: 15px;
        svg{
            width: 100%;
            height: 100%;
        }
    }
    &__arrow{
        margin: 0 5px 0 0;
        transition: transform .3s ease;
        svg{
            width: 30px;
            height: 30px;
        }
    }

}
.options{
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--scrollbar-thumb-color); 
        border-radius: 10px;
    }
    &__option-search-tags{
        background-color: #fff;
        border: 2px solid var(--side-menu-select-border);
        padding: 8px 0px;
        margin: 8px;
        position: relative;

        
    }
    &__search-tags{
        width: 100%;
        border: none;
        outline: none;
        padding: 8px;
        font-size: toRem(14);
        
        
    }
    &__clear{
        position: absolute;
        border: none;
        background-color: transparent;
        cursor: pointer;
        font-size: toRem(14);
        font-weight: 600;
        padding: 0;
        top: 50%;
        left: auto;
        right: 15px;
        transform: translate(0, -50%);
        svg{
            width: 20px;
            height: 20px;
        }
    }
    &__header{
        cursor: pointer;
        
    }
    &__title{
        svg{
            width: 20px;
            height: 20px;
            transition: transform .3s ease;
        }
        font-size: toRem(20);
        font-weight: 500;
        line-height: 120%;
        padding: 12px;
        display: flex;
        align-items: center;
        gap:10px;
    }
    &__body{
        height: 0;
        overflow: hidden;
        transition: height .3s ease;
        

    }
    &__option{
        padding: 12px 12px 12px 24px;
        cursor: pointer;
        &.active{
        color: #000;
        background-color: var(--side-menu-select-border);
        border-bottom: 1px solid #eaeaea;
        }
    }
    &__no-result-message{
        padding: 0px 0px 12px 12px;
    }
}
.active .options{
    &__body{
        height: 100%;
        transition: height .3s ease;
    }
    &__title{
        svg{
            transform: rotate(45deg);
            transition: transform .3s ease;
        }
    }
}
.open{
    & .custom-select{
        &__select-box{
            border-radius: 10px 10px 0 0;
            border-color: var(--side-menu-select-border-active);
        }
        &__options{
            display: block;
            border-color: var(--side-menu-select-border-active);
        }
    }
    & .select-box{
        &__arrow{
            transform: rotate(180deg);
            transition: transform .3s ease;
        }
    }
}

.btn-submit {
    
} */

.title{
    
}
.tabs {
    display: flex;
    flex-direction: column;
    &__navigation {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
    }

    &__link {
        padding: 10px;
        background-color: var(--tab-link-bg);
        border: 1px solid var(--tab-link-border);
        border-radius: 5px;
        color: var(--tab-link-color);
        text-align: center;
    }

    &__body {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
    }

    &__block {
    }
}
.block-tab {
    border-bottom: 1px solid var(--block-tab-border);
    padding-bottom: 10px;
    display: none;
    
    &:not(:last-child){
        margin-bottom: 15px;
    }

    &__title {
        font-size: toRem(36);
        font-weight: 500;
        line-height: 120%;
        margin-bottom: 15px;
        color: var(--block-tab-title);
    }

    &__body {
    }

    &__links {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        /* display: grid;
        grid-template-columns: repeat(5,1fr); */
        gap:20px;
    }

    &__link {
        font-size: toRem(24);
        line-height: 120%;
        padding: 5px;
        border: 1px solid var(--tab-link-border);
        border-radius: 10px;
        color: var(--tab-link-color);
        background-color: var(--tab-link-bg);
        text-align: center;
        white-space: nowrap;

    }
}
.block-tab.active{
    display: block;
}
/* .select-solo {
    position: relative;
   
    &__header{
        cursor: pointer;
        display: flex;
        
        
    }
    &__current {
            display: flex;
            align-items: center;
        img{
            width: 20px !important;
            height: 20px !important;
            cursor: pointer;
            position: absolute;
        }
    }
    &__icon{
        position: absolute;
        top: 50%;
        right: 0px;
	    height: 30px;
        width: 30px;
	    right: 15px;
	    text-align: center;	
    transition: all 0.3s ease;
    transform: translate(0,-50%);
    svg{
        width: 100%;
        height: 100%;
    }
    }
    &__body {
    border: 2px solid var(--side-menu-select-border-active);
	border-top: 0;
    display: none;
	left: 0;
	position: absolute;
	right: 0;
	top: 100%;
    background-color: #fff;
    max-height: 150px;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background: #ced4da; 
        border-radius: 10px;
    }
    }
    &__search{
        padding: 8px 0;
        margin: 8px;
        position: relative;
        border: 1px solid var(--side-menu-select-border);
        

    }
    &__search-input{
        width: 100%;
        padding: 8px;
        outline: none;
        border: none;
        font-size: toRem(14);
        

    }
    &__clear{
        position: absolute;
        width: 15px;
        height: 15px;
        top: 50%;
        right:5px;
        transform: translate(0,-50%);
        svg{

        }
    }

    
    &__item {
    cursor: pointer;
	font-size: 16px;
	line-height: 120%;
	padding: 12px 12px 12px 24px;
    }
    &.is-active .select-solo{
        &__header{
            border-radius: 10px 10px 0 0;
            border-color: var(--side-menu-select-border-active);
        }
        &__body{
            display: block;
            border-radius: 0 0 10px 10px;
            
        }
        &__icon{
            transform: translate(0,-50%) rotate(180deg);
            transition: all 0.3s ease;

        }
    }
} */
.price-filter .select-solo{
    &__body{
        z-index: 4;
    }
}
.gender-filter .select-solo{
    &__body{
        z-index: 3;
    }
}
.country-filter .select-solo{
    &__body{
        z-index: 2;
    }
}

/* .search-tag {
    width: 500px;
    position: relative;
    height: 40px;
    &__input {
        width: 100%;
        outline: 1px solid var(--side-menu-bg);
        color: var(--side-menu-bg2);
        font-size: toRem(20);
        line-height: 120%;
        height: 100%;
        border-radius: 5px;
        padding: 3px 15px 3px 15px;
        background-color: #e3f6f59f;
        &:focus-visible{
            border: none;
            outline: 3px solid var(--side-menu-bg);
        }
    }

    &__button {
        position: absolute;
        top: 50%;
        color: var(--side-menu-bg2);
        right: 10px;
        transform: translate(0,-50%);
        width: 20px;
        height: 20px;
    }
} */
@media (max-width:1050px){
    .form{
        width: 630px;
    }
}
@media (max-width:$tablet){
    
    .title{
        &__img{
            width: 40px;
            height: 40px;
        }
        &__text{
            font-size: toRem(40);
        }
    }
    .form{
        width: 580px;
    }
    .header{
        
    }
    .tabs{
        &__body{
            margin-top: 20px;
        }
        
    }
    .block-tab{
        &:not(:last-child){
            margin-bottom: 10px;
        }
        &__title{
            font-size: toRem(30);
            margin-bottom: 10px;
        }
        &__links{
            grid-template-columns: repeat(4,1fr);
            gap: 15px;
        }
        &__link{
            font-size: toRem(20);

        }
    }
}
@media (max-width:800px){
    
}
@media (max-width:$mobile){
    /* .page-main{
        padding-top: 50%;
    } */
    .logo{
        &__logo {
			display: flex;
			align-items: center;
			gap: 5px;
			svg{
				width: 24px;
				height: 24px;
				path{
				}
			}
			p{
				font-size: toRem(24);
				font-weight: 700;
				line-height: 120%;
			}
		}
    }
    .title{
        font-size: toRem(28);
        &__img{
            width: 36px;
            height: 36px;
        }
        &__text{
            font-size: toRem(36);
        }
    }
    .form{
        width: 480px;
    }
}
@media (max-width:570px) {
    
.page-categories, .page-location, .page-model{
    padding: 0px !important;
    
}
        
}
@media (max-width:570px) {
    .search-tag{
        width: 100%;
        &__input{
            
        }
    }
    .form{
        width: 400px;
    }
        
}

@media (max-width:$mobileSmall){
    .title-side-menu{
        font-size: toRem(18);
    }
    .title{
        font-size: toRem(24);
        gap: 10px;
        &__img{
            width: 30px;
            height: 30px;
        }
        &__text{
            font-size: toRem(30);
        }
    }
    .form{
        width: 260px;
    }
    
    
    .tabs{
        &__navigation{
            display: grid;
            grid-template-columns: repeat(6, 1fr);
        }
        &__body{
            margin-top: 20px;
        }
    }
    .block-tab{
        &__title{
            font-size: toRem(24);
        }
        &__links{
            display: flex;
            flex-wrap: wrap;
        }
        &__link{
            font-size: toRem(20);
        }
    }
}