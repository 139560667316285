.categories {

    &__container {
        padding-left: 50px;
    }

    &__body {
        display: flex;
        flex-direction: column;
    }

    &__title {
        
    }
    &__search{
        margin-top: 25px;
    }
    &__tabs {
        margin-top: 25px;
    }
}
