
.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: var(--page-bg);
	box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);	
	z-index: 2;
	position: relative;

	/* &__main-logo{
		position: absolute;
		width: 150px;
		height: 60px;
		top: 50%;
		left: 10px;
		transform: translate(0,-50%);
		img{
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	} */
	&__container {
		
	}

	&__body {
		display: flex;
		align-items: center;
		padding: 8px 0;
		gap: 20px;		
		transition: overflow .2s ease;
		
	}
	&__top{
		display: flex;
		flex-direction: column;
		gap: 20px;

	}
	&__logo {
		display: flex;
		gap: 5px;
		align-items: center;
		img{
			width: 130px;
		}
		/* svg{
				width: 50px;
				height: 50px;

		}
		p{
			font-size: toRem(30);
			line-height: 1.5;
			font-weight: 600;
		} */
	}
	&__toggle{
		display: none;
		svg{
			width: 40px;
			height: 40px;
		}
	}

	&__bottom {
		width: 100%;
	}

	&__form {
		width: 100% !important;
		
	}

	&__links {
		margin-top: 16px;
		justify-content: flex-start !important;
	}
}
@media (max-width:1366px){
	.header{
		/* &__main-logo{
			display: none;
		} */
	}
}
@media(max-width:$tablet){
	.header{
		/* &__main-logo{
			display: block;
			height: 40px;
			top:5px;
			left: 10px;
			transform: none;
		} */
		&__body{
			flex-direction: column;
			gap: 10px;			
			height: 50px;
			padding: 5px 0px;
			transition: height .2s ease;
			overflow: hidden;
		}
		&__top{
			flex-direction: row;
			align-items: center;
			justify-content: center;
		}
		&__logo{
			svg{
				width: 40px;
				height: 40px;
			}
			p{
				font-size: toRem(25);
			}
		}
		&__links{
			margin-top: 10px;
			width: 100% !important;
			justify-content: center !important;
		}
		&__top{

		}
		&__form{
			margin: 0 auto;
		}
		&__toggle{
			display: block;
			transition: transform .2s ease;
		}
		&__bottom{
			
		}
		&.open{
			& .header{
				&__body{
					height: 140px;
					transition: height overflow .2s ease;
					overflow: visible;
				}
				&__toggle{
					transform: rotate(180deg);
					transition: transform .2s ease;
				}
			}
			
			
		} 
	}
}
@media(max-width:560px){
	.header{
		&__form{
			width:100% !important;
		}
		
	}
}
@media(max-width:430px){
	.header{
		
		&__top{
			width: 100%;
			
		}
	}
}

