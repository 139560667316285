.dark-light {
    position: absolute;
    top: 10px;
    right: 20px;
    &__body {
    }

    &__toggle {
        width: 40px;
        height: 40px;
        background-color: var(--header-button-bg);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__light, &__dark {
        width: 30px;
        height: 30px;
       
    }
    &__light{
        display: block;
    }
    &__dark{
        display: none;
    }
}

