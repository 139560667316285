.model-page {

    &__container {
    }

    &__body {
        padding: 10px 10px 30px;
        display: grid;
        grid-template-columns: repeat(1,1fr);
        justify-items: center;        

    }

    &__image{
        width: 700px;
        aspect-ratio: 16/9;
        /* height: 300px; */
        img{
            width: 100%;
            height: 100%;
            object-fit: fill;
            border-radius: 10px;
            box-shadow: 0 1px 6px rgba(32,33,36,.28);
            transition: all .3s ease;
        }
    }
    &__image-medium-width{
        width: 500px;
        

    }
    &__image-small-width{
        width: 300px;
    }
    &__image-small-height{
        aspect-ratio: 4/5;
    }

    &__info {
        margin-top: 30px;
        
    }
    & .name {

        &__body {
            flex-direction: column;
            align-items: start;
        }
    
        &__nickname {
        }
    
        &__row {
        }
    
        &__link {
            padding-left: 0;
            &::before{
                content: none;
            }
        }
    
        &__add {
        }
    }
    & .description {

        &__text {
            -webkit-line-clamp: 20;
        }
    }
    & .social {

        &__link {
            /* svg{
                width: 40px;
                height: 40px;
            } */
        }
    }
}
@media(hover:hover){
    .model-page{
        &__image{
            &:hover{
                img{
                    transform: scale(1.05);
                    transition: all .3s ease;
                }
            }
        }
    }
}

@media(max-width:550px){
    .model-page{
        &__image{
            width: 100%;
            img{
                
            }
            
        }
    }
}
@media(max-width:$mobileSmall){
    .model-page{
        &__image{
            img{
                /* height: 300px; */
            }
            
        }
    }
}