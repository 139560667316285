.searcher {
    color: #000;
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        margin-top: 50px;
        max-width: 500px;
    }
    &__logo{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__text {
        margin-top: 40px;
        font-size: toRem(24);
        font-weight: 500;
    }
}


@media (max-width:$tablet){
    .searcher{
        &__title{
            margin-top: 30px;
        }
        &__text{
            margin-top: 20px;
            font-size: toRem(20);
        }
    }
    
}
@media (max-width:$mobile){
    .searcher{
        &__text{
            font-size: toRem(18);
        }
    }
}
@media(max-width:$mobileSmall){
    .searcher{
        &__text{
            text-align: center;
        }
    }
}