.location {

    &__container {
        padding-left: 50px;
    }

    &__body {
    }

    &__title {
    }

    &__search {
        margin-top: 25px;
    }

    &__tab {
        margin-top: 25px;
    }
    & .tabs{
        
    }
    & .block-tab{
        &__links{
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
        }
    }
}
