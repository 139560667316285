.paging {
    margin-top: 20px;
    &__body {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    &__item {
        padding: 5px;
        border-radius: 50%;
        overflow: hidden;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .3s ease;
        color: var(--paging-text-color);
        border: 2px solid var(--paging-border-color);
        cursor: pointer;

        p{
            font-size: toRem(16);
            line-height: 120%;
            font-weight: 600;
        }
        &.current{
            background-color: var(--paging-active-bg);
            color: var(--paging-text-hover-color);
        }
        &.disabled{
            filter: opacity(40%);
            cursor: default;
        }
    }

    &__left-arrow {
        transform: rotate(90deg);
        width: 20px;
        height: 20px;
        path{
            transition: fill .3s ease;
            fill: var(--paging-text-hover-color);
        }
    }

    &__dots {
        display: flex;
        gap: 5px;
    }

    &__dot {
        font-size: toRem(16);
        line-height: 120%;
        font-size: 600;
    }

    &__right-arrow {
        transform: rotate(-90deg);
        width: 20px;
        height: 20px;
        path{
            transition: fill .3s ease;
            fill: var(--paging-text-color);
        }
    }
}
@media (hover:hover){
    .paging{
        &__item{
            &:hover{
                background-color: var(--paging-background-hover);
                color: var(--paging-text-hover-color);
                transition: all .3s ease;
            }
            
            svg{
                &:hover{
                    path{
                        fill: var(--paging-text-hover-color);
                        
                    }
                }
            }
            &.disabled{
                &:hover{
                    background-color: transparent !important;
                    color: transparent !important;
                    transition: none;
                }
                svg{
                    &:hover{
                        path{
                            fill: var(--paging-text-hover-color);
                        }
                        
                    }
                }
            }
            
        }
    }
}
@media (max-width:$mobileSmall){
    .paging{
        &__body{
            gap: 5px;
        }
        &__item{
            p{
                font-size: toRem(14);
            }
        }
        &__dot{
            font-size: toRem(14);
        }
    }
}