@use "sass:math";

// Підключення міксинів ===========================================================================================================================================================================================================================================================================
@import "base/mixins";

// Підключення шрифтів ===========================================================================================================================================================================================================================================================================
//&display=swap - додати при підключенні через плагін

// Підключити, якщо є локальні файли шрифтів
//@import "fonts/fonts";

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

// Підключення іконкових шрифтів ==============================================================================================================================================================================================================================================================================
// Підключити, якщо є файл іконкового шрифту
//@import "fonts/icons";

// ============================================================================================================================================================================================================================================================================================================================================================================================
// Налаштування шаблону ============================================================================================================================================================================================================================================================================================================================================================================================
// ============================================================================================================================================================================================================================================================================================================================================================================================

// За замовчуванням шрифт ==============================================================================================================================================================================================================================================================================
$fontFamily: "Roboto";
$fontSize: toRem(16); // де 14(px) - розмір шрифту за замовчуванням з макету

// Основні кольори

/* $mainColor: #000; // Колір шрифту за замовчуванням
$pagebg:#E7E7E7;
$sidemenubg: #2c698d;
$sidemenubg2: #272643;
$liteblue: #bae8e8;
 */
 :root{
	/* //blue
	//header
	--header-bg: #2c698d;
	--header-border: #272643;
	--header-button-bg:#272643;
	--header-button-color:#fff;
	--header-logo-color:#272643;
	//input
	--input-border-color:#2c698d;
	--input-border-color-active:#272643;
	//main-page
	--text-color: #272643;
	--links-item-bg:#2c698d34;
	--links-item-text:#272643;
	--links-item-svg: #fff;
	--links-item-border: #272643;
	//card-item
	--card-item-border1: #272643;
	--card-item-border2:#2c698d;
	--card-item-price-bg:#272643;
	--card-item-price-color: #fff;
	--card-item-info-bg:#2c698d34;
	--card-item-info-values: #fff;
	--card-item-info-svg: #272643;
	//paging
	--paging-border-color:#272643;
	--paging-text-color: #000;
	--paging-text-hover-color: #fff;
	--paging-background-hover: #272643;
	--paging-active-bg: #272643;
	//category,location
	--tab-link-bg: #2c698d;
	--tab-link-border: #272643;
	--tab-link-color: #fff;
	--block-tab-title: #272643;
	--block-tab-border: #272643;
	//side-menu
	--side-menu-bg-main: #2c698d;
	--side-menu-border: #272643;
	--side-menu-title: #fff;
	--side-menu-svg:#fff;
	--side-menu-link-bg:#bae8e8;
	--side-menu-link-color:#000;
	--side-menu-link-hover-bg:#272643;
	--side-menu-link-hover-color: #fff;
	--side-menu-select-border:#2c698d;
	--side-menu-select-border-active: #272643;
	//scroll
	--scrollbar-track-bg:#2c698d;
	--scrollbar-thumb-color:#272643;
	//other
	--main-color-text: #000;
	--page-bg: #e7e7e7;
	--svg-fill:#272643;
	--title: #272643;	
	--side-menu-bg: #2c698d;
	--side-menu-bg2: #272643;
	--lite-blue: #bae8e8; */

	
	//pink
	//header
	--header-bg: #F8F8FF;
	--header-border: #FF69B4;
	--header-button-bg:#FF69B4;
	--header-button-color:#fff;
	--header-logo-color:#FF69B4;
	//input
	--input-border-color:#FFB6C1;
	--input-border-color-active:#FF69B4;
	//main-page
	--text-color: #FF69B4;
	--links-item-bg:#ffb6c126;
	--links-item-text:#FF69B4;
	--links-item-svg: #fff;
	--links-item-svg-text:#FF69B4;
	--links-item-border: #FFB6C1;
	//card-item
	--card-item-border1: #FF69B4;
	--card-item-border2:#FFB6C1;
	--card-item-price-bg:#FF69B4;
	--card-item-price-color: #fff;
	--card-item-info-bg:#2c698d34;
	--card-item-info-values: #fff;
	--card-item-info-svg: #272643;
	//paging
	--paging-border-color:#FF69B4;
	--paging-text-color: #696969;
	--paging-text-hover-color: #696969;
	--paging-background-hover: #FFB6C1;
	--paging-active-bg: #FFB6C1;
	//category,location
	--tab-link-bg: #FF69B4;
	--tab-link-border: #FFB6C1;
	--tab-link-color: #696969;
	--block-tab-title: #696969;
	--block-tab-border: #FF69B4;
	//side-menu
	--side-menu-bg-main: #F8F8FF;
	--side-menu-border: #FF69B4;
	--side-menu-title: #696969;
	--side-menu-svg:#696969;
	--side-menu-link-bg:#FFB6C1;
	--side-menu-link-color:#696969;
	--side-menu-link-hover-bg:#272643;
	--side-menu-link-hover-color: #fff;
	--side-menu-select-border:#FFB6C1;
	--side-menu-select-border-active: #FF69B4;
	//scroll
	--scrollbar-track-bg:#FFB6C1;
	--scrollbar-thumb-color:#FF69B4;
	//other
	--main-color-text: #000;
	--page-bg: #fcfcf8;
	--svg-fill:#272643;
	--title: #272643;	
	--side-menu-bg: #2c698d;
	--side-menu-bg2: #272643;
	--lite-blue: #bae8e8;
	//
	/* purple
	 //header
	--header-bg: #af00fb;
	--header-border: #5D0085;
	--header-button-bg:#5D0085;
	--header-button-color:#fff;
	--header-logo-color:#5D0085;
	//input
	--input-border-color:#af00fb;
	--input-border-color-active:#5D0085;
	//main-page
	--text-color: #5D0085;
	--links-item-bg:#5d008548;
	--links-item-text:#5D0085;
	--links-item-svg: #fff;
	--links-item-border: #af00fb;
	//card-item
	--card-item-border1: #af00fb;
	--card-item-border2:#5D0085;
	--card-item-price-bg:#5D0085;
	--card-item-price-color: #fff;
	--card-item-info-bg:#5d008548;
	--card-item-info-values: #fff;
	--card-item-info-svg: #5D0085;
	//paging
	--paging-border-color:#5D0085;
	--paging-text-color: #696969;
	--paging-text-hover-color: #fff;
	--paging-background-hover: #af00fb;
	--paging-active-bg: #af00fb;
	//category,location
	--tab-link-bg: #5D0085;
	--tab-link-border: #af00fb;
	--tab-link-color: #ddd6d6;
	--block-tab-title: #5D0085;
	--block-tab-border: #5D0085;
	//side-menu
	--side-menu-bg-main: #af00fb;
	--side-menu-border: #5D0085;
	--side-menu-title: #fff;
	--side-menu-svg:#fff;
	--side-menu-link-bg:#5D0085;
	--side-menu-link-color:#fff;
	--side-menu-link-hover-bg:#272643;
	--side-menu-link-hover-color: #fff;
	--side-menu-select-border:#af00fb;
	--side-menu-select-border-active: #5D0085;
	//scroll
	--scrollbar-track-bg:#af00fb;
	--scrollbar-thumb-color:#5D0085;
	//other
	--main-color-text: #000;
	--page-bg: #fff;
	--svg-fill:#272643;
	--title: #272643;	
	--side-menu-bg: #2c698d;
	--side-menu-bg2: #272643;
	--lite-blue: #bae8e8; */
/* 
	//green
	 //header
	--header-bg: #50C878;
	--header-border: #316000;
	--header-button-bg:#316000;
	--header-button-color:#fff;
	--header-logo-color:#316000;
	//input
	--input-border-color:#50C878;
	--input-border-color-active:#316000;
	//main-page
	--text-color: #316000;
	--links-item-bg:#3260004f;
	--links-item-text:#316000;
	--links-item-svg: #fff;
	--links-item-border: #4f9b00;
	//card-item
	--card-item-border1: #50C878;
	--card-item-border2:#316000;
	--card-item-price-bg:#316000;
	--card-item-price-color: #fff;
	--card-item-info-bg:#3260004f;
	--card-item-info-values: #fff;
	--card-item-info-svg: #316000;
	//paging
	--paging-border-color:#316000;
	--paging-text-color: #696969;
	--paging-text-hover-color: #fff;
	--paging-background-hover: #50C878;
	--paging-active-bg: #50C878;
	//category,location
	--tab-link-bg: #50C878;
	--tab-link-border: #316000;
	--tab-link-color: #316000;
	--block-tab-title: #316000;
	--block-tab-border: #316000;
	//side-menu
	--side-menu-bg-main: #50C878;
	--side-menu-border: #316000;
	--side-menu-title: #316000;
	--side-menu-svg:#316000;
	--side-menu-link-bg:#316000;
	--side-menu-link-color:#fff;
	--side-menu-link-hover-bg:#272643;
	--side-menu-link-hover-color: #fff;
	--side-menu-select-border:#50C878;
	--side-menu-select-border-active: #316000;
	//scroll
	--scrollbar-track-bg:#50C878;
	--scrollbar-thumb-color:#316000;
	//other
	--main-color-text: #000;
	--page-bg: #fff;
	--svg-fill:#272643;
	--title: #272643;	
	--side-menu-bg: #2c698d;
	--side-menu-bg2: #272643;
	--lite-blue: #bae8e8; */
	
 }
.darkmode{	
	//brown
	//header
	--header-bg: #696969;
	--header-border: #F4A460;
	--header-button-bg:#F4A460;
	--header-button-color:#fff;
	--header-logo-color:#F4A460;
	//input
	--input-border-color:#F5DEB3;
	--input-border-color-active:#F4A460;	
	//main-page
	--text-color: #F4A460;
	--links-item-bg:#f4a5602d;
	--links-item-text:#F5DEB3;
	--links-item-svg-text:#fff;
	--links-item-svg: #F4A460;
	--links-item-border: #F4A460;
	//card-item
	--card-item-border1: #F4A460;
	--card-item-border2:#F5DEB3;
	--card-item-price-bg:#F4A460;
	--card-item-price-color: #fff;
	--card-item-info-bg:#f4a5602d;
	--card-item-info-values: #fff;
	--card-item-info-svg: #F4A460;
	//paging
	--paging-border-color:#F5DEB3;
	--paging-text-color: #000;
	--paging-text-hover-color: #000;
	--paging-background-hover: #F4A460;
	--paging-active-bg: #F4A460;
	//category,location
	--tab-link-bg: #F4A460;
	--tab-link-border: #F5DEB3;
	--tab-link-color: #fff;
	--block-tab-title: #F5DEB3;
	--block-tab-border: #F4A460;
	//side-menu
	--side-menu-bg-main: #696969;
	--side-menu-border: #F4A460;
	--side-menu-title: #F4A460;
	--side-menu-svg:#F4A460;
	--side-menu-link-bg:#F4A460;
	--side-menu-link-color:#000;
	--side-menu-link-hover-bg:#272643;
	--side-menu-link-hover-color: #fff;
	--side-menu-select-border:#F5DEB3;
	--side-menu-select-border-active: #F4A460;
	//scroll
	--scrollbar-track-bg: #F5DEB3;
	--scrollbar-thumb-color:#F4A460;
	//other
	--main-color-text: #000;
	--page-bg: #696969;
	--svg-fill:#F4A460;
	--title: #272643;

	/* //pink
	//header
	--header-bg: #fcc8c4;
	--header-border: #F88379;
	--header-button-bg:#F88379;
	--header-button-color:#fff;
	--header-logo-color:#F88379;
	//input
	--input-border-color:#fcc8c4;
	--input-border-color-active:#F88379;	
	//main-page
	--text-color: #F88379;
	--links-item-bg:#f884792a;
	--links-item-text:#F88379;
	--links-item-svg: #fcc8c4;
	--links-item-border: #fcc8c4;
	//card-item
	--card-item-border1: #F88379;
	--card-item-border2:#fcc8c4;
	--card-item-price-bg:#F88379;
	--card-item-price-color: #fff;
	--card-item-info-bg:#f884792a;
	--card-item-info-values: #fff;
	--card-item-info-svg: #F88379;
	//paging
	--paging-border-color:#fcc8c4;
	--paging-text-color: #000;
	--paging-text-hover-color: #000;
	--paging-background-hover: #F88379;
	--paging-active-bg: #F88379;
	//category,location
	--tab-link-bg: #fcc8c4;
	--tab-link-border: #F88379;
	--tab-link-color: #696969;
	--block-tab-title: #F88379;
	--block-tab-border: #F88379;
	//side-menu
	--side-menu-bg-main: #fcc8c4;
	--side-menu-border: #F88379;
	--side-menu-title: #696969;
	--side-menu-svg:#696969;
	--side-menu-link-bg:#F88379;
	--side-menu-link-color:#696969;
	--side-menu-link-hover-bg:#272643;
	--side-menu-link-hover-color: #fff;
	--side-menu-select-border:#fcc8c4;
	--side-menu-select-border-active: #F88379;
	//scroll
	--scrollbar-track-bg: #fcc8c4;
	--scrollbar-thumb-color:#F88379;
	//other
	--main-color-text: #000;
	--page-bg: #696969;
	--svg-fill:#F88379;
	--title: #272643; */

	/* //purple
	//header
	--header-bg: #E0B0FF;
	--header-border: #AA98A9;
	--header-button-bg:#AA98A9;
	--header-button-color:#fff;
	--header-logo-color:#AA98A9;
	//input
	--input-border-color:#E0B0FF;
	--input-border-color-active:#AA98A9;	
	//main-page
	--text-color: #E0B0FF;
	--links-item-bg:#dfb0ff2a;
	--links-item-text:#AA98A9;
	--links-item-svg: #E0B0FF;
	--links-item-border: #AA98A9;
	//card-item
	--card-item-border1: #E0B0FF;
	--card-item-border2:#AA98A9;
	--card-item-price-bg:#E0B0FF;
	--card-item-price-color: #696969;
	--card-item-info-bg:#dfb0ff2a;
	--card-item-info-values: #fff;
	--card-item-info-svg: #F88379;
	//paging
	--paging-border-color:#E0B0FF;
	--paging-text-color: #FFFFF0;
	--paging-text-hover-color: #696969;
	--paging-background-hover: #AA98A9;
	--paging-active-bg: #AA98A9;
	//category,location
	--tab-link-bg: #AA98A9;
	--tab-link-border: #E0B0FF;
	--tab-link-color: #FFFFF0;
	--block-tab-title: #AA98A9;
	--block-tab-border: #AA98A9;
	//side-menu
	--side-menu-bg-main: #E0B0FF;
	--side-menu-border: #AA98A9;
	--side-menu-title: #FFFFF0;
	--side-menu-svg:#FFFFF0;
	--side-menu-link-bg:#AA98A9;
	--side-menu-link-color:#FFFFF0;
	--side-menu-link-hover-bg:#272643;
	--side-menu-link-hover-color: #fff;
	--side-menu-select-border:#E0B0FF;
	--side-menu-select-border-active: #AA98A9;
	//scroll
	--scrollbar-track-bg: #E0B0FF;
	--scrollbar-thumb-color:#AA98A9;
	//other
	--main-color-text: #000;
	--page-bg: #696969;
	--svg-fill:#F88379;
	--title: #272643; */

	/* //blue
	//header
	--header-bg: #4169E1;
	--header-border: #000080;
	--header-button-bg:#000080;
	--header-button-color:#fff;
	--header-logo-color:#000080;
	//input
	--input-border-color:#4169E1;
	--input-border-color-active:#000080;	
	//main-page
	--text-color: #000080;
	--links-item-bg:#0000803a;
	--links-item-text:#4169E1;
	--links-item-svg: #000080;
	--links-item-border: #000080;
	//card-item
	--card-item-border1: #000080;
	--card-item-border2:#4169E1;
	--card-item-price-bg:#000080;
	--card-item-price-color: #FFFFF0;
	--card-item-info-bg:#0000803a;
	--card-item-info-values: #FFFFF0;
	--card-item-info-svg: #000080;
	//paging
	--paging-border-color:#000080;
	--paging-text-color: #FFFFF0;
	--paging-text-hover-color: #FFFFF0;
	--paging-background-hover: #4169E1;
	--paging-active-bg: #4169E1;
	//category,location
	--tab-link-bg: #4169E1;
	--tab-link-border: #000080;
	--tab-link-color: #FFFFF0;
	--block-tab-title: #000080;
	--block-tab-border: #000080;
	//side-menu
	--side-menu-bg-main: #4169E1;
	--side-menu-border: #000080;
	--side-menu-title: #FFFFF0;
	--side-menu-svg:#FFFFF0;
	--side-menu-link-bg:#000080;
	--side-menu-link-color:#FFFFF0;
	--side-menu-link-hover-bg:#272643;
	--side-menu-link-hover-color: #fff;
	--side-menu-select-border:#4169E1;
	--side-menu-select-border-active: #000080;
	//scroll
	--scrollbar-track-bg: #4169E1;
	--scrollbar-thumb-color:#000080;
	//other
	--main-color-text: #000;
	--page-bg: #696969;
	--svg-fill:#F88379;
	--title: #272643;
	 */
}

// Палітра кольорів
// @import "base/colors-palette";

// Налаштування адаптивної сітки ===============================================================================================================================================================================================================================================================================================

// Мінімальна ширина сторінки
$minWidth: 320;
// Ширина полотна (макету)
$maxWidth: 1920;
// Ширина обмежуючого контейнера (0 = немає обмеження)
$maxWidthContainer: 1000;
// Загальний відступ у контейнера
// (30 = по 15px ліворуч і праворуч, 0 = немає відступу)
$containerPadding: 30;


// Ширина спрацьовування першого брейкпоінту
$containerWidth: $maxWidthContainer + $containerPadding;

// Брейк-поїнти
$pc: em($containerWidth); // ПК, ноутбуки, деякі планшети у горизонтальному положенні
$tablet: em(991.98); // Планшети, деякі телефони в горизонтальному положенні
$mobile: em(767.98); // Телефони L
$mobileSmall: em(479.98); // Телефони S

// Тип адаптива:
// 1 = чуйність (у контейнера немає брейкпоінтів),
// 2 = по брейк-поїнт (контейнер змінює свою ширину по брейк-поїнт)
$responsiveType: 1;

// ============================================================================================================================================================================================================================================================================================================================================================================================
// ============================================================================================================================================================================================================================================================================================================================================================================================

// Обнулення ============================================================================================================================================================================================== =============================================================================================== ===============================================================================================
@import "base/null";

// Стилі тега BODY ============================================================================================================================================================================================================================================================================================================================================================================================
body {
	background-color: var(--page-bg);
	// Скролл заблоковано
	&.lock {
		overflow: hidden;
		touch-action: none;
		overscroll-behavior: none;
	}
	// Сайт завантажений
	.loaded & {
	}
}
// Оболонка wrapper ============================================================================================================================================================================================================================================================================================================================================================================================================================================
/* width */
::-webkit-scrollbar {
	width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
	background: none;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 15px 0px 0px 15px;
	
  }
 
.wrapper {
	min-height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	background-color: var(--page-bg);
	
	@supports (overflow: clip) {
		overflow: clip;
	} 
	
	// Притискаємо footer
	> main {
		flex: 1 1 auto;
		padding-bottom: toRem(10);
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	// Фікс для слайдерів
	> * {
		min-width: 0;
	}
}


// Обмежуючий контейнер ======================================================================================================================================================================================================================
/*
(i) Стилі будуть застосовуватись до
всіх класів, що містять *__container
Наприклад header__container, main__container і т.п.
Сніппет (HTML): cnt
*/
@if ($responsiveType==1) {
	// Чуйна
	[class*="__container"] {
		@if ($maxWidthContainer>0) {
			max-width: toRem($containerWidth);
			margin: 0 auto;
		}
		@if ($containerPadding>0) {
			@if ($maxWidthContainer>0) {
				padding: 0 toRem(math.div($containerPadding, 2));
			} @else {
				@include adaptiveValue("padding-left", math.div($containerPadding, 2), 15);
				@include adaptiveValue("padding-right", math.div($containerPadding, 2), 15);
			}
		}
	}
} @else {
	// Брейк-поїнтами
	[class*="__container"] {
		margin: 0 auto;
		@if ($maxWidthContainer>0) {
			max-width: toRem($containerWidth);
		} @else {
			@if ($containerPadding>0) {
				padding: 0 toRem(math.div($containerPadding, 2));
			}
		}
		@media (max-width: $pc) {
			max-width: toRem(970);
		}
		@media (max-width: $tablet) {
			max-width: toRem(750);
		}
		@media (max-width: $mobile) {
			max-width: none;
			@if ($containerPadding>0 and $maxWidthContainer>0) {
				padding: 0 toRem(math.div($containerPadding, 2));
			}
		}
	}
}

/* .darkmode .dark-light{
	&__toggle{
		background-color: var(--header-button-bg);
	}
	&__light{
		display: none;
	
	}
	&__dark{
		display: block;
	}
} */

// Підключення базових стилів, шаблонів (заготівель) та допоміжних класів
// Для підключення/вимкнення конкретних стилів дивись base.scss
@import "base";

// Підключення стилів загальних елементів проекту
@import "common";
@import "header";
@import "footer";
// Підключення стилів окремих блоків
@import "dark-light";
@import "sideMenu";
@import "mainPagetext";
@import "searchModule";
@import "searchTime";
@import "model";
@import "navigation";
@import "cardItem";
@import "categories";
@import "location";
@import "models";
@import "paging";
