.side-menu {
   
    &__form{
        display: none;
    }
    

    &__body {
        width: 500px;
        background-color: var(--side-menu-bg-main);        
        position: fixed;
        left: -100%;
        height:100%;
        z-index: 4;
        top: 61px;
        padding: 20px 15px 150px 15px;
        transition: left .3s ease;
        overflow: auto;
        border-right: 2px solid var(--side-menu-border);
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-track {
            background: var(--scrollbar-track-bg);
          }
        &::-webkit-scrollbar-thumb {
            background: var(--scrollbar-thumb-color);
            border-radius: 10px 0px 0px 10px;
        }

    }
    /* &__logo{
        display: flex;
        color: var(--side-menu-bg2);
        svg{
            path{
                fill: var(--side-menu-bg2);
            }
        }
    } */

    &__categories {
    }
    &__locations {
    }
    
}
.opened {
    & .side-menu{
        
        &__body{
            left:0;
            transition: left .3s ease;
        }
        
    }
}
.categories {
    &__top-row{
        display: flex;
        gap: 20px;
        align-items: center;

    }
    &__image {
        fill:var(--side-menu-svg) !important;
        width: 30px;
        height: 30px;
    }

    &__title {
        //color: var(--side-menu-title);
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-top: 20px;
    }

    &__category {
        padding: 3px 5px;
        background-color: var(--side-menu-link-bg);
        color: var(--side-menu-link-color);
        border-radius: 10px;
        box-shadow: 2px 2px rgb(108, 106, 106);
        font-size: toRem(16);
        line-height: 120%;
    }
    &__btn{
        margin-top: 20px;
        width: 80%;
        display: block;
        background-color: var(--side-menu-link-bg);
        padding: 5px 10px;
        border-radius: 10px;

        p{
            font-size: toRem(16);
            color: var(--side-menu-link-color);
            font-weight: 500;
            line-height: 120%;
            text-align: center;
        }
    }
}

.locations {
    margin-top: 30px;
    margin-bottom: 30px;
    &__top-row {
        display: flex;
        gap: 20px;
        align-items: center;
    }

    &__image {
        width: 30px;
        height: 30px;
        path,circle{
            fill: var(--side-menu-svg);
        }
    }

    &__title {
        //color:var(--side-menu-title);
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-top: 20px;
    }

    &__location {
        padding: 3px 5px;
        background-color: var(--side-menu-link-bg);
        border-radius: 10px;
        box-shadow: 2px 2px rgb(108, 106, 106);
        color: var(--side-menu-link-color);
        font-size: toRem(16);
            line-height: 120%;
       
    }

    &__btn {
        margin-top: 20px;
        width: 80%;
        display: block;
        background-color: var(--side-menu-link-bg);
        padding: 5px 10px;
        border-radius: 10px;

        p{
            font-size: toRem(16);
            color: var(--side-menu-link-color);
            font-weight: 500;
            line-height: 120%;
            text-align: center;
        }
    }
}
.page-categories .locations{
    margin-top: 10px;

}
.page-location .categories{
    margin-top: 10px;
}
@media (max-width:$tablet){
    .side-menu{
        &__body{
            top: 117px;
        }
    }
}
@media (max-width:800px){
    .side-menu{
        &__form{
            display: block;
            color: #000;
            width: 100%;
        }
    }
}
@media(max-width:$mobile){
    .side-menu{
        &__body{
            left: -170%;
        }
        &__form{
            width: 95%;
        }
    }
    .categories{
        &__top-row{
            gap: 15px;
        }
        
        &__image{
            width: 25px;
            height: 25px;
        }
        &__wrapper{
            gap: 10px;
            margin-top: 15px;
        }
        &__btn{
            width: 80%;
            margin: 15px auto 0;
        }
    }
    .locations{
        margin-top: 20px;
        margin-bottom: 20px;
        &__top-row{
            gap: 15px;
        }
        &__image{
            width: 25px;
            height: 25px;
        }
        &__wrapper{
            gap: 10px;
            margin-top: 15px;
        }
        &__btn{
            width: 80%;
            margin: 15px auto 0;
        }
    }
}
@media(max-width:600px){
    .side-menu{
        & .logo{
            display: flex;
        }
        &__body{
            top: 102px;
        }
    }
}
@media (max-width:560px){
    .side-menu{
        &__body{
            width: 100%;
        }
        &__form{
            width: 90%;
            margin: 0 auto;
            & .select-box{
                &__tag{
                    padding: 6px 7px;
                }
            }
        }
    }
    .categories,.locations{
        &__wrapper{
            width: 80%;
            margin: 15px auto 0;
        }
    }
}
@media (max-width:$mobileSmall){
    .side-menu{
        &__form{
            width: 100%;
        }
    }
    .side-menu .custom-select{
        &__body{
            width: 100%;
        }
    }
    
    .categories,.locations{
        gap: 10px;
        &__image{
            width: 20px;
            height: 20px;
        }
        &__wrapper{
            gap: 10px;
        }    
        &__btn{
            p{
                font-size: toRem(14);
            }
        }    
    }
    .categories{
        &__category{
            font-size: toRem(14);
        }
    }
    .locations{
        &__location{
            font-size: toRem(14);
        }
    }
}
@media(max-width:330px){
    .side-menu__form .select-box{
        &__tag{
            padding: 3px 5px;
        }
    }
}